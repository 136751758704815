import { createMachine } from 'xstate/lib/Machine';
import { assign } from 'xstate/lib/actions';

export type Aggregations =
  GatsbyTypes.categoryPageWithProductsQuery['sus']['products']['aggregations'];

export type AttributeMetaData = {
  attribute_code: string;
  attribute_type: string;
  input_type: string;
}[];

export type FilterOption = {
  label: string;
  value: string | number;
  count?: number;
};

export type FilterItem = {
  id: string | number;
  label: string;
  activeValues?: { value: string | number; label?: string }[];
  multiSelect?: boolean;
  options: FilterOption[];
};

export type ActiveFilter = {
  attributeCode: string;
  activeOptions: { value: any; label: string }[];
};

export interface FilterContext {
  items: FilterItem[];
  aggregations: Aggregations;
  meta: AttributeMetaData;
  activeFilters?: ActiveFilter[];
  updatedAggregations?: Aggregations;
}

type FilterEvent =
  | { type: 'FILTER_CHANGE'; values: any }
  | { type: 'FILTER_RESET' }
  | { type: 'FILTER_AGGREGATION_UPDATE'; aggregations: Aggregations };

type FilterTypeState =
  | {
      value: 'pristine';
      context: FilterContext;
    }
  | {
      value: 'active';
      context: FilterContext;
    };

const EMPTY_ARRAY = [];

export const filterMachine = createMachine<
  FilterContext,
  FilterEvent,
  FilterTypeState
>(
  {
    id: 'filter',
    initial: 'pristine',
    context: {
      items: [],
      activeFilters: undefined,
      aggregations: undefined,
      updatedAggregations: [undefined],
      meta: undefined,
    },
    on: {
      FILTER_CHANGE: [
        {
          cond: (ctx, event) => !filtersAreEmpty(event.values),
          actions: [
            assign((ctx, event) => {
              return { activeFilters: activeFilters(event.values, ctx) };
            }),
          ],
          target: 'active',
        },
        {
          cond: (ctx, event) => filtersAreEmpty(event.values),
          target: 'pristine',
        },
      ],
      FILTER_RESET: {
        target: 'pristine',
      },
      FILTER_AGGREGATION_UPDATE: {
        actions: [
          assign((ctx, event) => {
            return { updatedAggregations: event.aggregations };
          }),
          'updateFiltersItems',
        ],
      },
    },
    states: {
      pristine: {
        entry: [
          assign((ctx, event) => ({
            updatedAggregations: undefined,
            activeFilters: undefined,
          })),
          'setDefaultFilters',
        ],
      },
      active: {},
    },
  },
  {
    actions: {
      setDefaultFilters: assign(ctx => {
        const aggregations = ctx.aggregations;
        const meta = ctx.meta;
        return { items: defaultItems(aggregations, meta) };
      }),
      updateFiltersItems: assign((ctx, event) => {
        const { updatedAggregations, activeFilters, aggregations, meta } = ctx;
        if (updatedAggregations && activeFilters) {
          const newActiveFilters = activeFilters.map<FilterItem>(filter => {
            const aggregation = aggregations?.find(
              agg => agg.attribute_code === filter.attributeCode
            );

            // check the updated aggregations for new options
            const options =
              updatedAggregations.find(
                updAgg => updAgg.attribute_code === filter.attributeCode
              )?.options || [];

            return {
              id: filter.attributeCode,
              multiSelect: shouldBeMulti(
                meta.find(
                  info => info.attribute_code === aggregation?.attribute_code
                )
              ),
              label: aggregation?.label,
              options: options?.map<FilterOption>(opt => ({
                ...opt,
              })),
              activeValues: filter.activeOptions,
            };
          });

          const updatedFilters = updatedAggregations
            .filter(
              agg =>
                agg.attribute_code !== 'category_id' &&
                !activeFilters.some(i => i.attributeCode === agg.attribute_code)
            )
            .map(aggregation => {
              return {
                id: aggregation.attribute_code,
                inputType: shouldBeMulti(
                  meta.find(
                    info => info.attribute_code === aggregation.attribute_code
                  )
                ),
                label: aggregation.label,
                options: aggregation.options.map<FilterOption>(opt => ({
                  ...opt,
                })),
              };
            });

          return { items: newActiveFilters.concat(updatedFilters) };
        }
        return {};
      }),
    },
  }
);

export function filtersAreEmpty(filterValues) {
  return (
    Object.values(filterValues).filter(val =>
      Array.isArray(val) ? val.length : val
    ).length === 0
  );
}

function shouldBeMulti(meta?: AttributeMetaData[0]): FilterItem['multiSelect'] {
  switch (meta?.input_type) {
    case 'multiselect':
    case 'select':
    case 'boolean':
      return true;
    case 'price':
      return false;

    default:
      return true;
  }
}

function defaultItems(aggregations: Aggregations, meta: AttributeMetaData) {
  return aggregations
    .filter(agg => agg.attribute_code !== 'category_id')
    .map(aggregation => {
      return {
        id: aggregation.attribute_code,
        multiSelect: shouldBeMulti(
          meta?.find(info => info.attribute_code === aggregation.attribute_code)
        ),
        label: aggregation.label,
        options: aggregation.options?.map<FilterOption>(opt => ({
          ...opt,
        })),
      };
    });
}

function activeFilters(
  filterValues: Record<string, any>,
  ctx: FilterContext
): ActiveFilter[] {
  const { updatedAggregations, aggregations, meta } = ctx;

  const activeFilters: ActiveFilter[] = Object.entries(filterValues)
    .filter(([, value]) => (Array.isArray(value) ? !!value.length : !!value))
    .map<ActiveFilter>(([attributeCode, value]) => ({
      attributeCode,
      activeOptions: Array.isArray(value)
        ? value.map(v => ({
            value: v,
            label: findOption(
              [
                ...(aggregations || EMPTY_ARRAY),
                ...(updatedAggregations || []),
              ],
              attributeCode,
              v
            )?.label,
          }))
        : [
            {
              value,
              label: findOption(aggregations, attributeCode, value)?.label,
            },
          ],
    }));

  return activeFilters;
}

export function findOption(
  aggregations: Aggregations,
  attributeCode: string,
  value: any
) {
  return aggregations
    ?.find(agg => agg.attribute_code === attributeCode)
    ?.options.find(opt => opt.value === value);
}
