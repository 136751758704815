import { BREAK_POINTS } from '@sus-core/utils/breakpoints';
import { useState, useEffect } from 'react';

export const MEDIA_QUERY = { IS_MOBILE: `(max-width: ${BREAK_POINTS.MD}px)` };

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };

    media.addEventListener('change', listener);

    return () => {
      media.removeEventListener('change', listener);
    };
  }, [matches, query]);

  return matches;
}
