import { ApolloClient } from '@apollo/client/core/ApolloClient';
import { InMemoryCache } from '@apollo/client/cache/inmemory/inMemoryCache';
import { createHttpLink } from '@apollo/client/link/http/createHttpLink';
import { onError } from '@apollo/client/link/error';

import { setContext } from '@apollo/client/link/context';

import fetch from 'isomorphic-fetch';

import possibleTypes from './../possibleTypes.json';
import { storage } from '@sus-core/utils/window';
import { GraphQlExeptions } from './apiErrors';

export const AUTH_TOKEN_KEY = 'sus-auth-token';

const httpLink = createHttpLink({
  fetch,
  uri: process.env.GATSBY_MAGENTO_GQL_URI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = storage.getItem(AUTH_TOKEN_KEY);
  // return the headers to the context so httpLink can read them
  const Authorization = token ? `Bearer ${token}` : undefined;
  return {
    headers: {
      ...headers,
      Authorization,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      process.env.NODE_ENV === 'development' &&
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

      if (
        !['generateCustomerToken'].includes(path + '') &&
        (extensions?.category === GraphQlExeptions.AUTHENTICATION ||
          extensions?.category === GraphQlExeptions.AUTHORIZATION)
      ) {
        if (typeof window !== 'undefined') {
          window.dispatchEvent(new Event('AUTH_ERROR'));
        }
      }
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const cache = new InMemoryCache({
  possibleTypes,
  resultCaching: true,
});

export const gqlClient = new ApolloClient({
  cache,
  link: authLink.concat(errorLink).concat(httpLink),
});
