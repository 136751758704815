export const windowGlobal: Window = typeof window !== 'undefined' && window;

export const storage: Storage = windowGlobal
  ? windowGlobal.localStorage
  : {
      getItem: () => null,
      setItem: () => null,
      clear: () => null,
      key: () => null,
      length: 0,
      removeItem: () => null
    };
