import { MEDIA_QUERY } from '@sus-core/hooks/useMediaQuery';

import { Machine } from 'xstate/lib/Machine';
import { interpret, spawn } from 'xstate/lib/interpreter';
import { assign } from 'xstate/lib/actions';
import { ActorRefFrom } from 'xstate/lib/types';
import {
  Aggregations,
  AttributeMetaData,
  filterMachine,
} from '../filter/filter.machine';

export type FilterActor = ActorRefFrom<typeof filterMachine>;

export interface AppContext {
  query?: string;
  searchFilter?: any;
  filters: Record<string, FilterActor>;
}

interface AppStateSchema {
  states: {
    desktop: any;
    mobile: any;
  };
}

interface SwitchMobileEvent {
  type: 'SWITCH_MOBILE';
}

interface SwitchDesktopeEvent {
  type: 'SWITCH_DESKTOP';
}

interface OpenCartEvent {
  type: 'CART_OPEN';
}

interface CloseCartEvent {
  type: 'CART_CLOSE';
}

interface OpenMenuEvent {
  type: 'MENU_OPEN';
}

interface CloseMenuEvent {
  type: 'MENU_CLOSE';
}

/// search related events
interface OpenSearchEvent {
  type: 'SEARCH_OPEN';
}

interface CloseSearchEvent {
  type: 'SEARCH_CLOSE';
}

///

interface FilterInitEvent {
  type: 'FILTER_INIT';
  key: string;
  aggregations: Aggregations;
  meta: AttributeMetaData;
}

type AppEvent =
  | SwitchMobileEvent
  | SwitchDesktopeEvent
  | OpenCartEvent
  | CloseCartEvent
  | OpenMenuEvent
  | CloseMenuEvent
  | OpenSearchEvent
  | CloseSearchEvent
  | FilterInitEvent;

const cartStates = {
  initial: 'closed',
  states: {
    open: {
      on: {
        CART_CLOSE: 'closed',
      },
    },
    closed: {
      on: {
        CART_OPEN: 'open',
      },
    },
  },
};

const searchStates = {
  initial: 'closed',
  states: {
    open: {
      on: {
        SEARCH_CLOSE: 'closed',
      },
    },
    closed: {
      on: {
        SEARCH_OPEN: 'open',
      },
    },
  },
};

const appMachine = Machine<AppContext, AppStateSchema, AppEvent>(
  {
    id: 'app',
    initial: 'desktop',
    context: {
      filters: {},
    },
    on: {
      SWITCH_MOBILE: 'mobile',
      SWITCH_DESKTOP: 'desktop',
      FILTER_INIT: {
        actions: [
          assign((ctx, event) => {
            const { key, meta, aggregations } = event;
            if (key && !ctx.filters[key]) {
              const actor = spawn(
                filterMachine.withContext({
                  items: [],
                  aggregations,
                  meta,
                }),
                { sync: true }
              );
              return {
                filters: {
                  ...ctx.filters,
                  [key]: actor as ActorRefFrom<typeof filterMachine>, // @TODO fix types,s o we don´t need to cast here
                },
              };
            }

            return {};
          }),
        ],
      },
    },
    states: {
      mobile: {
        type: 'parallel',
        states: {
          menu: {
            initial: 'closed',
            states: {
              open: {
                on: {
                  MENU_CLOSE: { target: 'closed' },
                },
              },
              closed: {
                on: {
                  MENU_OPEN: { target: 'open' },
                },
              },
            },
          },
          sidecart: {
            ...cartStates,
          },
          search: {
            ...searchStates,
          },
        },
      },
      desktop: {
        type: 'parallel',
        states: {
          sidecart: {
            ...cartStates,
          },
          search: {
            ...searchStates,
          },
        },
      },
    },
  },
  {}
);

export const appService = interpret(appMachine).start();

export function init() {
  if (typeof window !== 'undefined') {
    const media = window.matchMedia(MEDIA_QUERY.IS_MOBILE);
    appService.send({
      type: media.matches ? 'SWITCH_MOBILE' : 'SWITCH_DESKTOP',
    });

    media.addEventListener('change', event => {
      appService.send({
        type: event.matches ? 'SWITCH_MOBILE' : 'SWITCH_DESKTOP',
      });
    });
  }
}

export function openMenu() {
  appService.send({
    type: 'MENU_OPEN',
  });
}

export function closeMenu() {
  appService.send({
    type: 'MENU_CLOSE',
  });
}

export function openCart() {
  appService.send({
    type: 'CART_OPEN',
  });
}

export function closeCart() {
  appService.send({
    type: 'CART_CLOSE',
  });
}

export function openSearch() {
  appService.send({
    type: 'SEARCH_OPEN',
  });
}

export function closeSearch() {
  appService.send({
    type: 'SEARCH_CLOSE',
  });
}

export function getFilters(
  key: string | number,
  aggregations: Aggregations,
  meta: AttributeMetaData
) {
  appService.send({ type: 'FILTER_INIT', aggregations, key: key + '', meta });

  return appService.getSnapshot().context.filters[key];
}
