import { ApolloError } from '@apollo/client/errors/index';

export enum GraphQlExeptions {
  ENITY_ALREADY_EXISTS = 'graphql-already-exists',
  NO_SUCH_ENTITY = 'graphql-no-such-entity',
  AUTHENTICATION = 'graphql-authentication',
  AUTHORIZATION = 'graphql-authorization',
  INVALID_INPUT = 'graphql-input',
}

export function getEntityErrors(error: ApolloError) {
  const { graphQLErrors = [] } = error || {};
  return graphQLErrors.filter(
    err => err?.extensions?.category === GraphQlExeptions.NO_SUCH_ENTITY
  );
}

export function containsEntityErrors(error: ApolloError): boolean {
  return getEntityErrors(error).length > 0;
}
